<template>
  <Form @submit="save">
    <div class="md:grid md:grid-cols-2 md:gap-6">
      <div v-if="user.role === 'TENANT' || user.role === 'DEVELOPER'">
        <h4>Rolle</h4>
        <BasicInput
          id="chooseRole"
          v-model="employee.role"
          as="select"
          input-class="appearance-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
          label="Rolle/Berechtigung"
          label-class="block text-sm font-medium text-gray-700"
          rules="required"
        >
          <option
            selected
            disabled
          >
            bitte wählen
          </option>
          <option
            value="TENANT"
          >
            {{ roles["TENANT"].human }}
          </option>
          <option
            value="EMPLOYEE"
          >
            {{ roles["EMPLOYEE"].human }}
          </option>
        </BasicInput>
      </div>
      <div class="col-span-1 col-start-1">
        <h4>Allgemeine Informationen</h4>
        <div class="grid grid-cols-6 gap-2">
          <div class="col-span-2">
            <div class="mt-1">
              <BasicInput
                id="firstname"
                v-model="employee.firstname"
                type="text"
                name="firstname"
                input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                label-class="block text-sm font-medium text-gray-700"
                label="Vorname"
                rules="required"
              />
            </div>
          </div>
          <div class="col-span-2">
            <div class="mt-1">
              <BasicInput
                id="lastname"
                v-model="employee.lastname"
                type="text"
                name="lastname"
                input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                label-class="block text-sm font-medium text-gray-700"
                label="Nachname"
                rules="required"
              />
            </div>
          </div>
          <div class="col-span-3">
            <BasicInput
              id="street"
              v-model="employee.address.street"
              type="text"
              name="street"
              input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              label="Straße"
              rules="required"
              label-class="block text-sm font-medium text-gray-700"
            />
          </div>
          <div class="col-span-1">
            <BasicInput
              id="number"
              v-model="employee.address.streetNumber"
              type="text"
              name="number"
              input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              label="Nr."
              rules="required"
              label-class="block text-sm font-medium text-gray-700"
            />
          </div>
          <div class="col-start-1 col-span-1">
            <BasicInput
              id="zip"
              v-model="employee.address.zip"
              type="text"
              name="zip"
              input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              label="PLZ"
              rules="required"
              label-class="block text-sm font-medium text-gray-700"
            />
          </div>
          <div class="col-span-3">
            <BasicInput
              id="city"
              v-model="employee.address.city"
              type="text"
              name="city"
              input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              label="Ort"
              rules="required"
              label-class="block text-sm font-medium text-gray-700"
            />
          </div>
        </div>
      </div>
      <div class="col-span-1 col-start-1">
        <h4>
          Logindaten
        </h4>
        <div class="grid grid-cols-6 gap-2">
          <div class="col-span-4">
            <div class="mt-1">
              <BasicInput
                id="email"
                v-model="employee.email"
                input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                label="Email"
                rules="required|email-pattern"
                label-class="block text-sm font-medium text-gray-700"
              />
            </div>
          </div>
          <div class="col-span-4">
            <div class="mt-1">
              <BasicInput
                id="password"
                v-model="password"
                type="password"
                name="password"
                input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-r-0"
                label="Neues Passwort"
                label-class="block text-sm font-medium text-gray-700"
                :rules="{'password-strength': true, required: requirePassword}"
                button-style="border-gray-300"
                show-button
                shadow
              />
            </div>
          </div>
          <div class="col-span-4">
            <div class="mt-1">
              <BasicInput
                id="confirmpassword"
                type="password"
                name="confirmpassword"
                input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-r-0"
                label="Neues Passwort bestätigen"
                label-class="block text-sm font-medium text-gray-700"
                rules="confirmed:@password"
                button-style="border-gray-300"
                show-button
                shadow
              />
            </div>
          </div>
          <div class="col-start-1 col-span-4 mt-2">
            <div class="flex justify-end">
              <button
                type="button"
                class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                @click="$router.go(-1)"
              >
                Zurück
              </button>
              <input
                type="submit"
                value="Speichern"
                class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>
<script>

import { models } from '@/feathers-client'
import {Form} from 'vee-validate'
import roles from '../../../helpers/roles.js'
import BasicInput from '../../../components/BasicInput.vue'

export default {
  components: { BasicInput, Form },
  props:{
    employeeProp: {
      type: Object,
      default: new models.api.User({
        firstname: '',
        lastname:'',
        role: 'EMPLOYEE',
        email: '',
        address: {
          street: '',
          streetNumber:'',
          zip: '',
          city: '',
        }
      })
    },
    requirePassword:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      password: undefined,
      employee: this.employeeProp,
      roles
    }
  },
  computed:{
    user(){
      return this.$store.getters['auth/user']
    }
  },
  methods: {
    async save (){
      this.employee.tenantId = this.$route.params.tenantId
      if(this.password) {
        this.employee.password = this.password
      }
      try {
        await this.employee.save()
        this.$router.push({name: 'EmployeesList'})
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
