<template>
  <div class="h-screen bg-white overflow-hidden flex">
    <div class="sm:flex sm:flex-shrink-0">
      <div class="w-64 flex flex-col">
        <div class="border-r border-gray-200 pt-5 pb-4 flex flex-col flex-grow overflow-y-auto">
          <div class="flex-shrink-0 flex items-center px-4 pb-4">
            <img
              class="w-full"
              src="@/assets/images/mydocuments-logo.svg"
              alt="Workflow"
            >
          </div>
          <div
            v-if="user.role=='DEVELOPER' && tenantDetail"
            class="flex flex-col px-2"
          >
            <hr>
            <div class="border-1 rounded mx-2 text-gray-600 font-bold py-2">
              {{ tenantName }}
            </div>
            <hr>
          </div>
          <div class="flex-grow mt-5 flex flex-col">
            <div class="flex-1">
              <nav
                v-if="user.role=='DEVELOPER' && !tenantDetail"
                class=" bg-white px-2 space-y-1"
              >
                <router-link
                  :to="{ name: 'TenantList' }"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                >
                  <!-- Heroicon name: users -->
                  <FontAwesomeIcon
                    size="lg"
                    class="mr-2"
                    :icon="faUserSecret"
                  />
                  Makler
                </router-link>
                <router-link
                  :to="{ name: 'ContractKindsList' }"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                >
                  <!-- Heroicon name: users -->
                  <FontAwesomeIcon
                    size="lg"
                    class="mr-2"
                    :icon="faBuilding"
                  />
                  Vertragsarten
                </router-link>
                <router-link
                  :to="{ name: 'ContractCompaniesList' }"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                >
                  <!-- Heroicon name: users -->
                  <FontAwesomeIcon
                    size="lg"
                    class="mr-2"
                    :icon="faBuilding"
                  />
                  Gesellschaften
                </router-link>
                <hr class="mx-2 my-3">
              </nav>
              <nav
                v-if="tenantDetail"
                class="bg-white px-2 space-y-1"
              >
                <router-link
                  v-if="user.role=='DEVELOPER'"
                  :to="{ name: 'TenantList', params: { tenantId } }"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                >
                  <FontAwesomeIcon
                    size="lg"
                    class="mr-2"
                    :icon="faArrowCircleLeft"
                  />
                  Zurück
                </router-link>
                <router-link
                  :to="{ name: 'CustomerList', params: { tenantId } }"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                >
                  <FontAwesomeIcon
                    size="lg"
                    class="mr-2"
                    :icon="faUsers"
                  />
                  Kunden
                </router-link>
                <!-- <router-link
                  :to="{ name: 'InvitationList', params: { tenantId } }"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                >
                  <FontAwesomeIcon
                    size="lg"
                    class="mr-2"
                    :icon="faUsers"
                  />
                  Einladungen
                </router-link> -->
                <router-link
                  :to="{ name: 'EmployeesList', params: { tenantId } }"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                >
                  <!-- Heroicon name: users -->
                  <FontAwesomeIcon
                    size="lg"
                    class="mr-2"
                    :icon="faUsers"
                  />
                  Mitarbeiter
                </router-link>
                <router-link
                  :to="{ name: 'FaqList', params: { tenantId } }"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                >
                  <!-- Heroicon name: users -->
                  <FontAwesomeIcon
                    size="lg"
                    class="mr-2"
                    :icon="faQuestion"
                  />
                  FAQ
                </router-link>
                <router-link
                  :to="{ name: 'AppAppearance', params: { tenantId } }"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                >
                  <!-- Heroicon name: users -->
                  <FontAwesomeIcon
                    size="lg"
                    class="mr-2"
                    :icon="faPalette"
                  />
                  Gestaltung
                </router-link>
                <router-link
                  :to="{ name: 'AppTexts', params: { tenantId } }"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                >
                  <!-- Heroicon name: users -->
                  <FontAwesomeIcon
                    size="lg"
                    class="mr-2"
                    :icon="faText"
                  />
                  Texte
                </router-link>
                <router-link
                  :to="{ name: 'IndividualLinks', params: { tenantId } }"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                >
                  <!-- Heroicon name: users -->
                  <FontAwesomeIcon
                    size="lg"
                    class="mr-2"
                    :icon="faExternalLink"
                  />
                  Links
                </router-link>
                <router-link
                  :to="{ name: 'Account', params: { tenantId } }"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                >
                  <!-- Heroicon name: users -->
                  <FontAwesomeIcon
                    size="lg"
                    class="mr-2"
                    :icon="faAlignLeft"
                  />
                  Konto
                </router-link>
                <router-link
                  :to="{ name: 'ContractCompaniesUserList', params: { tenantId } }"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                >
                  <!-- Heroicon name: users -->
                  <FontAwesomeIcon
                    size="lg"
                    class="mr-2"
                    :icon="faBuilding"
                  />
                  Gesellschaften
                </router-link>
              </nav>
            </div>
          </div>
          <div class="flex-shrink px-2">
            <button
              type="button"
              class="flex w-full justify-center px-2 py-1 border text-sm font-medium rounded-md shadow-sm text-gray-900 border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-500 hover:text-white"
              @click="logout"
            >
              Abmelden
            </button>
            <div class="text-center mt-2">
              <router-link
                :to="{ name: 'Impressum' }"
                class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 items-center text-sm font-medium "
              >
                Impressum
              </router-link>
              <router-link
                :to="{ name: 'Privacy' }"
                class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 items-center text-sm font-medium "
              >
                Datenschutz
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1 mx-auto w-0 flex flex-col">
      <main
        class="flex-1 relative overflow-y-auto focus:outline-none"
        tabindex="0"
      >
        <router-view />
      </main>
    </div>
  </div>
</template>
<script>
import { faUsers,  faUserSecret, faPalette, faAlignLeft, faQuestion, faBuilding, faExternalLink, faText, faArrowCircleLeft } from '@fortawesome/pro-light-svg-icons'
//import TenantSelect from './TenantSelect.vue'

export default {
  components: {  },
  data() {
    return {
      faUsers,
      faUserSecret,
      faPalette,
      faAlignLeft,
      faBuilding,
      faQuestion,
      faExternalLink,
      faText,
      faArrowCircleLeft
    }
  },
  computed: {
    tenantList() {
      return this.$store.getters['tenants/list']
    },
    tenantId() {
      return this.$route.params.tenantId || this.$store.state.auth.user.tenantId
    },
    tenantName(){
      return this.tenantList.find(tenant => tenant._id === this.tenantId)?.name
    },
    tenantDetail() {
      return this.$route.matched.some(item => item.path.includes(':tenantId'))
    },
    user(){
      return this.$route.params.user || this.$store.state.auth.user
    }
  },
  methods: {
    logout(){
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
