<template>
  <div v-if="tenant">
    <LayoutHeader>
      <template #default>
        Makler
      </template>
    </LayoutHeader>
    <div
      class="p-4"
    >
      <TenantComponent :tenant-to-edit="tenant" />
    </div>
  </div>
</template>
<script>
import roles from '../../helpers/roles.js'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import store from '@/store'
import LayoutHeader from '../../components/LayoutHeader.vue'
import TenantComponent from './components/TenantComponent.vue'

export default {
  components: { LayoutHeader, TenantComponent },
  data () {
    return {
      roles,
      dayjs,
      tenant: null
    }
  },
  computed:{
  },
  created(){
    this.fetchData()
  },
  methods: {
    async fetchData(){
      this.tenant = await store.dispatch('tenants/get', this.$route.params.tenantId)
    },
    async deleteTenant(){
      try {
        await this.$store.dispatch('tenants/remove', this.$route.params.tenantId)
        this.$router.push({ name: 'TenantList' })
      } catch (error) {
        console.error(error)
      }
    },
  }
}
</script>
