<template>
  <div>
    <LayoutHeader>
      <template #default>
        Makler
      </template>
      <template #actions>
        <button
          type="button"
          class="ml-3 inline-flex items-center px-4 py-2 border border-green-600 rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          @click="$router.push({name: 'TenantCreate'})"
        >
          Hinzufügen
        </button>
      </template>
    </LayoutHeader>
    <div class="m-2">
      <BasicInput
        id="tenantSearch"
        v-model="tenantSearch"
        label="Makler suchen"
        input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
        label-class="block text-sm font-medium text-gray-700"
        @input="debounceSearch"
      />
    </div>
    <table class="min-w-full divide-y divide-gray-200 border-t">
      <thead class="bg-gray-50">
        <tr>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <SortButton
              v-model="sortObj"
              name-in-mongo="name"
              @update:modelValue="fetchData"
            >
              Name
            </SortButton>
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Adresse
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <SortButton
              v-model="sortObj"
              name-in-mongo="createdAt"
              @update:modelValue="fetchData"
            >
              Erstellt Am
            </SortButton>
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Status
          </td>
          <td />
        </tr>
      </thead>
      <tbody
        v-if="tenants"
        class="bg-white divide-y divide-gray-200"
      >
        <tr
          v-for="tenant of tenants"
          :key="tenant._id"
        >
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ tenant.name }}
          </td>
          <td
            v-if="tenant.address"
            class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
          >
            {{ tenant.address.street }} {{ tenant.address.streetNumber }}, {{ tenant.address.zip }} {{ tenant.address.city }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ dayjs(tenant.createdAt).format('DD.MM.YYYY HH:mm') }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <span
              v-if="tenant.status === 'STORNO'"
              class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
            >
              Storno
            </span>
            <span
              v-else-if="tenant.status === 'ANBAHNUNG'"
              class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"
            >
              Anbahnung
            </span>
            <span
              v-else
              class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
            >
              Aktiv
            </span>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <router-link
              class="btn btn-sm btn-success text-indigo-600 text-sm hover:text-indigo-700 btn-table"
              :to="{ name: 'TenantEdit', params: { tenantId: tenant._id }}"
            >
              Bearbeiten
            </router-link>
            <router-link
              :to="{ name: 'CustomerList', params: { tenantId: tenant._id } }"
                class="btn btn-sm btn-success text-indigo-600 text-sm hover:text-indigo-700 btn-table"
            >
                Makler auswählen
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      v-if="totalNumber"
      v-model="page"
      :number-of-items="perPage"
      :total-number="totalNumber"
      @update:modelValue="fetchData"
    />
  </div>
</template>
<script>
import roles from '../../helpers/roles.js'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import store from '@/store'
import LayoutHeader from '../../components/LayoutHeader.vue'
import SortButton from '../../components/SortButton.vue'
import Pagination from '../../components/Pagination.vue'
import BasicInput from '../../components/BasicInput.vue'
import debounce from 'lodash/debounce'

export default {
  components: { LayoutHeader, Pagination, SortButton, BasicInput },
  data () {
    return {
      roles,
      dayjs,
      tenants: null,
      tenantSearch: '',
      //Pagination
      totalNumber: null,
      perPage: 50, // Eintraege pro Seite
      page: 1, // Aktuelle Seite
      //SortButton
      sortObj: {
        sortBy: 'name', //propertyNameInMongo
        sortDirection: 1 // -1 = inverse
      }
    }
  },
  computed: {

  },
  created(){
    this.fetchData()
  },
  methods: {
    debounceSearch: debounce(async function(){this.fetchData()}, 200),
    async fetchData(){
      let req = await store.dispatch('tenants/find', {
        query: {
          name: {$regex: this.tenantSearch, $options: 'i'},
          $limit: this.perPage, $skip: (this.page -1)* this.perPage,
          $sort:{[this.sortObj.sortBy]: this.sortObj.sortDirection},
        }})
      this.tenants = req.data
      this.totalNumber = req.total
    }
  }
}
</script>
