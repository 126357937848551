<template>
  <div>
    <LayoutHeader>
      <template #default>
        Kunden
      </template>
      <template #actions>
        <button
          type="button"
          class="ml-3 inline-flex items-center px-4 py-2 border border-green-600 rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          @click="$refs.modal.showModal()"
        >
          Einladen
        </button>
      </template>
    </LayoutHeader>
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <SortButton
              v-model="sortObj"
              name-in-mongo="firstname"
              @update:modelValue="fetchData"
            >
              Vorame
            </SortButton>
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <SortButton
              v-model="sortObj"
              name-in-mongo="lastname"
              @update:modelValue="fetchData"
            >
              Nachname
            </SortButton>
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            E-Mail
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Rolle
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <SortButton
              v-model="sortObj"
              name-in-mongo="createdAt"
              @update:modelValue="fetchData"
            >
              Erstellt Am
            </SortButton>
          </td>
          <td />
        </tr>
      </thead>
      <tbody
        v-if="users"
        class="bg-white divide-y divide-gray-200"
      >
        <tr
          v-for="user of users"
          :key="user._id"
        >
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ user.firstname }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ user.lastname }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ user.email }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ roles[user.role].human }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ dayjs(user.createdAt).format('DD.MM.YYYY HH:mm') }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <router-link
              class="btn btn-sm btn-success text-indigo-600 text-sm hover:text-indigo-700 btn-table"
              :to="{ name: 'CustomerDetail', params: { userId: user._id }}"
            >
              Details
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      v-if="totalNumber"
      v-model="page"
      :number-of-items="perPage"
      :total-number="totalNumber"
      @update:modelValue="fetchData"
    />
    <div class="p-4">
      <InviteCustomerModal
        ref="modal"
        @fetchData="fetchData()"
      />
    </div>
  </div>
</template>
<script>
import roles from '../../helpers/roles.js'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import LayoutHeader from '../../components/LayoutHeader.vue'
import Pagination from '../../components/Pagination.vue'
import SortButton from '../../components/SortButton.vue'
import InviteCustomerModal from '@/components/modals/InviteCustomerModal'

export default {
  components: {
    LayoutHeader, Pagination, SortButton, InviteCustomerModal
  },
  data () {
    return {
      roles,
      dayjs,
      inviteCustomer: false,
      users: null,
      //Pagination
      totalNumber: null,
      perPage: 50, // Eintraege pro Seite
      page: 1, // Aktuelle Seite
      //SortButton
      sortObj: {
        sortBy: 'name', //propertyNameInMongo
        sortDirection: 1 // -1 = inverse
      }
    }
  },
  computed: {
  },
  watch: {
    '$route': {
      handler: 'fetchData',
      immediate: true
    }
  },
  methods:{
    async fetchData () {
      if(this.$route.params.tenantId && this.$route.params.tenantId !== 'null') {
        let req = await this.$store.dispatch('users/find', { query: {
          tenantId:  this.$route.params.tenantId, role: 'CUSTOMER',
          $limit: this.perPage, $skip: (this.page -1)* this.perPage,
          $sort:{[this.sortObj.sortBy]: this.sortObj.sortDirection}}})

        this.users = req.data
        this.totalNumber = req.total
      }
    }
  }
}
</script>
