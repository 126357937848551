<template>
  <div>
    <LayoutHeader>
      Mitarbeiter Informationen
    </LayoutHeader>
    <div
      v-if="employee"
      class="p-4"
    >
      <dl class="pt-4 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 mb-3">
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Name
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ employee.firstname }} {{ employee.lastname }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Email
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ employee.email }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Adresse
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ employee.address.street }} {{ employee.address.streetNumber }} <br>
            {{ employee.address.zip }} {{ employee.address.city }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Rolle
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ roles[employee.role].human }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Erstellt am
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ dayjs(employee.createdAt).format('DD.MM.YYYY HH:mm') }}
          </dd>
        </div>
      </dl>
      <button
        type="button"
        class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 float-right"
        @click="$router.go(-1)"
      >
        Zurück
      </button>
    </div>
  </div>
</template>
<script>
import LayoutHeader from '../../components/LayoutHeader.vue'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import roles from '../../helpers/roles.js'

export default {
  components: { LayoutHeader },
  data(){
    return{
      dayjs,
      roles,
      employee: null,
      password: null
    }
  },
  created(){
    this.fetchData()
  },
  methods: {
    async fetchData(){
      this.employee = await this.$store.dispatch('users/get', this.$route.params.employeeId)
      if (this.employee.address == null) {
        this.employee.address = {
          street: '',
          streetNumber: '',
          zip: '',
          city:''
        }
      }
    },
    async save(){
      this.employee.password = this.password
      try {
        await this.employee.save()
      } catch (error) {
        console.error(error)
      }
      this.$router.push({name: 'EmployeesList'})
    }
  }

}
</script>
